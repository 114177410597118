export default [
    {
        letter: "А",
        regions: [
            {
                id: 1,
                name: "Алтайский край",
                letter: "А",
                cities: [
                    { 
                        name: "Алейск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Алейск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Алейск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Барнаул",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Белокуриха",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бийск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Горняк",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Заринск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Змеиногорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Камень-на-Оби",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новоалтайск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Рубцовск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Славгород",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Яровое",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 2,
                name: "Амурская область",
                letter: "А",
                cities: [
                    { 
                        name: "Белогорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Благовещенск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Завитинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Зея",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Райчихинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Свободный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сковородино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тында",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Циолковский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шимановск",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 3,
                name: "Архангельская область",
                letter: "А",
                cities: [
                    { 
                        name: "Архангельск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Вельск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Каргополь",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Коряжма",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мезень",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мирный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новодвинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Няндома",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Онега",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Северодвинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сольвычегодск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шенкурск",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 4,
                name: "Астраханская область",
                letter: "А",
                cities: [
                    { 
                        name: "Астрахань",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Ахтубинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Знаменск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Камызяк",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нариманов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Харабали",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "Б",
        regions: [
            {
                id: 5,
                name: "Белгородская область",
                letter: "Б",
                cities: [
                    { 
                        name: "Алексеевка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Белгород",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Бирюч",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Валуйки",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Грайворон",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Губкин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Короча",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новый Оскол",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Старый Оскол",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Строитель",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шебекино",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 6,
                name: "Брянская область",
                letter: "Б",
                cities: [
                    { 
                        name: "Брянск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Дятьково",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Жуковка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Злынка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Карачев",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Клинцы",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мглин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новозыбков",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Почеп",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Севск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сельцо",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Стародуб",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сураж",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Трубчевск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Унеча",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "В",
        regions: [
            {
                id: 7,
                name: "Владимирская область",
                letter: "В",
                cities: [
                    { 
                        name: "Александров",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Владимир",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Вязники",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Гороховец",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Гусь-Хрустальный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Камешково",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Карабаново",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Киржач",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ковров",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Кольчугино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Костерёво",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Курлово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лакинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Меленки",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Муром",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Петушки",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Покров",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Радужный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Собинка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Струнино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Судогда",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Суздаль",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Юрьев-Польский",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 8,
                name: "Волгоградская область",
                letter: "В",
                cities: [
                    { 
                        name: "Волгоград",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Волжский",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Дубовка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Жирновск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Калач-на-Дону",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Камышин",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Котельниково",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Котово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Краснослободск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ленинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Михайловка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Николаевск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новоаннинский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Палласовка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Петров Вал",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Серафимович",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Суровикино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Урюпинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Фролово",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 9,
                name: "Вологодская область",
                letter: "В",
                cities: [
                    { 
                        name: "Бабаево",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Белозерск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Великий Устюг",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Вологда",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Вытегра",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Грязовец",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кадников",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кириллов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Красавино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Никольск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сокол",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тотьма",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Устюжна",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Харовск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Череповец",
                        primary: true,
						secondary: false
                    },
                ]
            },
            {
                id: 10,
                name: "Воронежская область",
                letter: "В",
                cities: [
                    { 
                        name: "Бобров",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Богучар",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Борисоглебск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бутурлиновка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Воронеж",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Калач",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лиски",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нововоронеж",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новохопёрск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Острогожск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Павловск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Поворино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Россошь",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Семилуки",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Эртиль",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "Е",
        regions: [
            {
                id: 11,
                name: "Еврейская автономная область",
                letter: "Е",
                cities: [
                    { 
                        name: "Биробиджан",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Облучье",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "З",
        regions: [
            {
                id: 12,
                name: "Забайкальский край",
                letter: "З",
                cities: [
                    { 
                        name: "Балей",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Борзя",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Краснокаменск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Могоча",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нерчинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Петровск-Забайкальский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сретенск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Хилок",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чита",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Шилка",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "И",
        regions: [
            {
                id: 13,
                name: "Ивановская область",
                letter: "И",
                cities: [
                    { 
                        name: "Вичуга",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Гаврилов Посад",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Заволжск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Иваново",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Кинешма",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Комсомольск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кохма",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Наволоки",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Плёс",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Приволжск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пучеж",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Родники",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тейково",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Фурманов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шуя",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Южа",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Юрьевец",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 14,
                name: "Иркутская область",
                letter: "И",
                cities: [
                    { 
                        name: "Алзамай",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ангарск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Байкальск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бирюсинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бодайбо",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Братск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Вихоревка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Железногорск-Илимский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Зима",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Иркутск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Киренск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нижнеудинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Саянск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Свирск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Слюдянка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тайшет",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тулун",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Усолье-Сибирское",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Усть-Илимск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Усть-Кут",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Черемхово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шелехов",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "К",
        regions: [
            {
                id: 15,
                name: "Кабардино-Балкария",
                letter: "К",
                cities: [
                    { 
                        name: "Баксан",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Майский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нальчик",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Нарткала",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Прохладный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Терек",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тырныауз",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чегем",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 16,
                name: "Калининградская область",
                letter: "К",
                cities: [
                    { 
                        name: "Багратионовск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Балтийск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Гвардейск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Гурьевск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Гусев",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Зеленоградск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Калининград",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Краснознаменск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ладушкин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мамоново",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Неман",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нестеров",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Озёрск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пионерский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Полесск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Правдинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Приморск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Светлогорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Светлый",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Славск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Советск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Черняховск",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 17,
                name: "Калужская область",
                letter: "К",
                cities: [
                    { 
                        name: "Балабаново",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Белоусово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Боровск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ермолино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Жиздра",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Жуков",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Калуга",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Киров",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Козельск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кондрово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кремёнки",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Людиново",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Малоярославец",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Медынь",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мещовск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мосальск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Обнинск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Сосенский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Спас-Деменск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сухиничи",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Таруса",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Юхнов",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 18,
                name: "Камчатский край",
                letter: "К",
                cities: [
                    { 
                        name: "Вилючинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Елизово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Петропавловск-Камчатский",
                        primary: true,
						secondary: false
                    },
                ]
            },
            {
                id: 19,
                name: "Карачаево-Черкесия",
                letter: "К",
                cities: [
                    { 
                        name: "Карачаевск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Теберда",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Усть-Джегута",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Черкесск",
                        primary: true,
						secondary: false
                    },
                ]
            },
            {
                id: 20,
                name: "Кемеровская область",
                letter: "К",
                cities: [
                    { 
                        name: "Анжеро-Судженск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Белово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Берёзовский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Гурьевск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Калтан",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кемерово",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Киселёвск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ленинск-Кузнецкий",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мариинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Междуреченск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мыски",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новокузнецк",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Осинники",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Полысаево",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Прокопьевск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Салаир",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тайга",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Таштагол",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Топки",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Юрга",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 21,
                name: "Кировская область",
                letter: "К",
                cities: [
                    { 
                        name: "Белая Холуница",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Вятские Поляны",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Зуевка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Киров",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Кирово-Чепецк",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кирс",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Котельнич",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Луза",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Малмыж",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мураши",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нолинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Омутнинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Орлов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Слободской",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Советск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сосновка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Уржум",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Яранск",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 22,
                name: "Костромская область",
                letter: "К",
                cities: [
                    { 
                        name: "Буй",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Волгореченск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Галич",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кологрив",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кострома",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Макарьев",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мантурово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нерехта",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нея",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Солигалич",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чухлома",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шарья",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 23,
                name: "Краснодарский край",
                letter: "К",
                cities: [
                    { 
                        name: "Абинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Анапа",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Апшеронск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Армавир",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Белореченск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Геленджик",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Горячий Ключ",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Гулькевичи",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ейск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кореновск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Краснодар",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Кропоткин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Крымск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Курганинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лабинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новокубанск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новороссийск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Приморско-Ахтарск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Славянск-на-Кубани",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сочи",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Темрюк",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тимашёвск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тихорецк",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Туапсе",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Усть-Лабинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Хадыженск",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 24,
                name: "Красноярский край",
                letter: "К",
                cities: [
                    { 
                        name: "Артёмовск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ачинск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Боготол",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бородино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дивногорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дудинка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Енисейск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Железногорск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Заозёрный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Зеленогорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Игарка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Иланский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Канск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кодинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Красноярск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Лесосибирск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Минусинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Назарово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Норильск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Сосновоборск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ужур",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Уяр",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шарыпово",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 25,
                name: "Курганская область",
                letter: "К",
                cities: [
                    { 
                        name: "Далматово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Катайск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Курган",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Куртамыш",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Макушино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Петухово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шадринск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шумиха",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Щучье",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 26,
                name: "Курская область",
                letter: "К",
                cities: [
                    { 
                        name: "Дмитриев",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Железногорск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Курск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Курчатов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Льгов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Обоянь",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Рыльск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Суджа",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Фатеж",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Щигры",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "Л",
        regions: [
            {
                id: 27,
                name: "Ленинградская область",
                letter: "Л",
                cities: [
                    { 
                        name: "Бокситогорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Волосово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Волхов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Всеволожск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Выборг",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Высоцк",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Гатчина",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Ивангород",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Каменногорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кингисепп",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кириши",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кировск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Коммунар",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кудрово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лодейное Поле",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Луга",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Любань",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мурино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Никольское",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новая Ладога",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Отрадное",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пикалёво",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Подпорожье",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Приморск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Приозерск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Светогорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сертолово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сланцы",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сосновый Бор",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сясьстрой",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тихвин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тосно",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шлиссельбург",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 28,
                name: "Липецкая область",
                letter: "Л",
                cities: [
                    { 
                        name: "Грязи",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Данков",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Елец",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Задонск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лебедянь",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Липецк",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Усмань",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чаплыгин",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "М",
        regions: [
            {
                id: 29,
                name: "Магаданская область",
                letter: "М",
                cities: [
                    { 
                        name: "Магадан",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Сусуман",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 30,
                name: "Москва",
                letter: "М",
                cities: [
                    { 
                        name: "Москва",
                        primary: true,
						secondary: false
                    },
                ]
            },
            {
                id: 31,
                name: "Московская область",
                letter: "М",
                cities: [
                    { 
                        name: "Апрелевка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Балашиха",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Бронницы",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Верея",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Видное",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Волоколамск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Воскресенск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Высоковск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Голицыно",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дедовск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дзержинский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дмитров",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Долгопрудный",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Домодедово",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Дрезна",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дубна",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Егорьевск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Жуковский",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Зарайск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Звенигород",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ивантеевка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Истра",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кашира",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Клин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Коломна",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Королёв",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Котельники",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Красноармейск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Красногорск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Краснозаводск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Краснознаменск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кубинка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Куровское",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ликино-Дулёво",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лобня",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лосино-Петровский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Луховицы",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лыткарино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Люберцы",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Можайск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мытищи",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Наро-Фоминск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ногинск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Одинцово",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Озёры",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Орехово-Зуево",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Павловский Посад",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пересвет",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Подольск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Протвино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пушкино",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Пущино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Раменское",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Реутов",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Рошаль",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Руза",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сергиев Посад",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Серпухов",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Солнечногорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Старая Купавна",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ступино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Талдом",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Фрязино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Химки",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Хотьково",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Черноголовка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чехов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шатура",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Щёлково",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Электрогорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Электросталь",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Электроугли",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Яхрома",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 32,
                name: "Мурманская область",
                letter: "М",
                cities: [
                    { 
                        name: "Апатиты",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Гаджиево",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Заозёрск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Заполярный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кандалакша",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кировск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ковдор",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кола",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мончегорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мурманск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Оленегорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Островной",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Полярные Зори",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Полярный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Североморск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Снежногорск",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "Н",
        regions: [
            {
                id: 33,
                name: "Ненецкий автономный округ",
                letter: "Н",
                cities: [
                    { 
                        name: "Нарьян-Мар",
                        primary: true,
						secondary: false
                    },
                ]
            },
            {
                id: 34,
                name: "Нижегородская область",
                letter: "Н",
                cities: [
                    { 
                        name: "Арзамас",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Балахна",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Богородск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бор",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ветлуга",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Володарск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ворсма",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Выкса",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Горбатов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Городец",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дзержинск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Заволжье",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Княгинино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кстово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кулебаки",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лукоянов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лысково",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Навашино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нижний Новгород",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Павлово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Первомайск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Перевоз",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Саров",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Семёнов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сергач",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Урень",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чкаловск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шахунья",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 35,
                name: "Новгородская область",
                letter: "Н",
                cities: [
                    { 
                        name: "Боровичи",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Валдай",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Великий Новгород",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Малая Вишера",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Окуловка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пестово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сольцы",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Старая Русса",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Холм",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чудово",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 36,
                name: "Новосибирская область",
                letter: "Н",
                cities: [
                    { 
                        name: "Барабинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бердск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Болотное",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Искитим",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Карасук",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Каргат",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Куйбышев",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Купино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новосибирск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Обь",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Татарск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тогучин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Черепаново",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чулым",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "О",
        regions: [
            {
                id: 37,
                name: "Омская область",
                letter: "О",
                cities: [
                    { 
                        name: "Исилькуль",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Калачинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Называевск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Омск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Тара",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тюкалинск",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 38,
                name: "Оренбургская область",
                letter: "О",
                cities: [
                    { 
                        name: "Абдулино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бугуруслан",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бузулук",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Гай",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кувандык",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Медногорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новотроицк",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Оренбург",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Орск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Соль-Илецк",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сорочинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ясный",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 39,
                name: "Орловская область",
                letter: "О",
                cities: [
                    { 
                        name: "Болхов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дмитровск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ливны",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Малоархангельск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мценск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новосиль",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Орёл",
                        primary: true,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "П",
        regions: [
            {
                id: 40,
                name: "Пензенская область",
                letter: "П",
                cities: [
                    { 
                        name: "Белинский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Городище",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Заречный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Каменка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кузнецк",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нижний Ломов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Никольск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пенза",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Сердобск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Спасск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сурск",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 41,
                name: "Пермский край",
                letter: "П",
                cities: [
                    { 
                        name: "Александровск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Березники",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Верещагино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Горнозаводск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Гремячинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Губаха",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Добрянка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кизел",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Красновишерск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Краснокамск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кудымкар",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кунгур",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лысьва",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нытва",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Оса",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Оханск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Очёр",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пермь",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Соликамск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Усолье",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чайковский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чердынь",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чёрмоз",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чернушка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чусовой",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 42,
                name: "Приморский край",
                letter: "П",
                cities: [
                    { 
                        name: "Арсеньев",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Артём",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Большой Камень",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Владивосток",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Дальнегорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дальнереченск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лесозаводск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Находка",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Партизанск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Спасск-Дальний",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Уссурийск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Фокино",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 43,
                name: "Псковская область",
                letter: "П",
                cities: [
                    { 
                        name: "Великие Луки",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Гдов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дно",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Невель",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новоржев",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новосокольники",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Опочка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Остров",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Печоры",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Порхов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Псков",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Пустошка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пыталово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Себеж",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "Р",
        regions: [
            {
                id: 44,
                name: "Республика Адыгея",
                letter: "Р",
                cities: [
                    { 
                        name: "Адыгейск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Майкоп",
                        primary: true,
						secondary: false
                    },
                ]
            },
            {
                id: 45,
                name: "Республика Алтай",
                letter: "Р",
                cities: [
                    { 
                        name: "Горно-Алтайск",
                        primary: true,
						secondary: false
                    },                    
                ]
            },
            {
                id: 46,
                name: "Республика Башкортостан",
                letter: "Р",
                cities: [
                    { 
                        name: "Агидель",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Баймак",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Белебей",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Белорецк",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бирск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Благовещенск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Давлеканово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дюртюли",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ишимбай",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кумертау",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Межгорье",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мелеуз",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нефтекамск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Октябрьский",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Салават",
                        primary: false,
						secondary: true
                    },
                    {
                        name: "Сибай",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Стерлитамак",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Туймазы",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Уфа",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Учалы",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Янаул",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 47,
                name: "Республика Бурятия",
                letter: "Р",
                cities: [
                    { 
                        name: "Бабушкин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Гусиноозёрск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Закаменск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кяхта",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Северобайкальск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Улан-Удэ",
                        primary: true,
						secondary: false
                    },
                ]
            },
            {
                id: 48,
                name: "Республика Дагестан",
                letter: "Р",
                cities: [
                    { 
                        name: "Буйнакск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дагестанские Огни",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дербент",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Избербаш",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Каспийск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Кизилюрт",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кизляр",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Махачкала",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Хасавюрт",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Южно-Сухокумск",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 49,
                name: "Республика Ингушетия",
                letter: "Р",
                cities: [
                    { 
                        name: "Карабулак",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Магас",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Малгобек",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Назрань",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Сунжа",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 50,
                name: "Республика Калмыкия",
                letter: "Р",
                cities: [
                    { 
                        name: "Городовиковск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лагань",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Элиста",
                        primary: true,
						secondary: false
                    },
                ]
            },
            {
                id: 51,
                name: "Республика Карелия",
                letter: "Р",
                cities: [
                    { 
                        name: "Беломорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кемь",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кондопога",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Костомукша",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лахденпохья",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Медвежьегорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Олонец",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Петрозаводск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Питкяранта",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пудож",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сегежа",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сортавала",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Суоярви",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 52,
                name: "Республика Коми",
                letter: "Р",
                cities: [
                    { 
                        name: "Воркута",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Вуктыл",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Емва",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Инта",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Микунь",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Печора",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сосногорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сыктывкар",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Усинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ухта",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 53,
                name: "Республика Крым",
                letter: "Р",
                cities: [
                    { 
                        name: "Алупка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Алушта",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Армянск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бахчисарай",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Белогорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Джанкой",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Евпатория",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Керчь",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Красноперекопск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Саки",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Симферополь",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Старый Крым",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Судак",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Феодосия",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Щёлкино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ялта",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 54,
                name: "Республика Марий Эл",
                letter: "Р",
                cities: [
                    { 
                        name: "Волжск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Звенигово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Йошкар-Ола",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Козьмодемьянск",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 55,
                name: "Республика Мордовия",
                letter: "Р",
                cities: [
                    { 
                        name: "Ардатов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Инсар",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ковылкино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Краснослободск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Рузаевка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Саранск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Темников",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 56,
                name: "Республика Саха (Якутия)",
                letter: "Р",
                cities: [
                    { 
                        name: "Алдан",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Верхоянск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Вилюйск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ленск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мирный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нерюнгри",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нюрба",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Олёкминск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Покровск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Среднеколымск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Томмот",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Удачный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Якутск",
                        primary: true,
						secondary: false
                    },
                ]
            },
            {
                id: 57,
                name: "Республика Северная Осетия — Алания",
                letter: "Р",
                cities: [
                    { 
                        name: "Алагир",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ардон",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Беслан",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Владикавказ",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Дигора",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Моздок",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 58,
                name: "Республика Татарстан",
                letter: "Р",
                cities: [
                    { 
                        name: "Агрыз",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Азнакаево",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Альметьевск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Арск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бавлы",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Болгар",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бугульма",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Буинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Елабуга",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Заинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Зеленодольск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Иннополис",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Казань",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Кукмор",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лаишево",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лениногорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мамадыш",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Менделеевск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мензелинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Набережные Челны",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Нижнекамск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Нурлат",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тетюши",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чистополь",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 59,
                name: "Республика Тыва",
                letter: "Р",
                cities: [
                    { 
                        name: "Ак-Довурак",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кызыл",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Туран",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чадан",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шагонар",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 60,
                name: "Республика Хакасия",
                letter: "Р",
                cities: [
                    { 
                        name: "Абаза",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Абакан",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Саяногорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Черногорск",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 61,
                name: "Ростовская область",
                letter: "Р",
                cities: [
                    { 
                        name: "Азов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Аксай",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Батайск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Белая Калитва",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Волгодонск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Гуково",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Донецк",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Зверево",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Зерноград",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Каменск-Шахтинский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Константиновск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Красный Сулин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Миллерово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Морозовск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новочеркасск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Новошахтинск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Пролетарск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ростов-на-Дону",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Сальск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Семикаракорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Таганрог",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Цимлянск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шахты",
                        primary: false,
						secondary: true
                    },
                ]
            },
            {
                id: 62,
                name: "Рязанская область",
                letter: "Р",
                cities: [
                    { 
                        name: "Касимов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кораблино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Михайлов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новомичуринск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Рыбное",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ряжск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Рязань",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Сасово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Скопин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Спас-Клепики",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Спасск-Рязанский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шацк",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "С",
        regions: [
            {
                id: 63,
                name: "Самарская область",
                letter: "С",
                cities: [
                    { 
                        name: "Жигулёвск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кинель",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нефтегорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новокуйбышевск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Октябрьск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Отрадный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Похвистнево",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Самара",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Сызрань",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Тольятти",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Чапаевск",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 64,
                name: "Санкт-Петербург",
                letter: "С",
                cities: [
                    { 
                        name: "Санкт-Петербург",
                        primary: true,
						secondary: false
                    },
                ]
            },
            {
                id: 65,
                name: "Саратовская область",
                letter: "С",
                cities: [
                    { 
                        name: "Аркадак",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Аткарск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Балаково",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Балашов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Вольск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ершов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Калининск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Красноармейск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Красный Кут",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Маркс",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новоузенск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Петровск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пугачёв",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ртищево",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Саратов",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Хвалынск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шиханы",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Энгельс",
                        primary: false,
						secondary: true
                    },
                ]
            },
            {
                id: 66,
                name: "Сахалинская область",
                letter: "С",
                cities: [
                    { 
                        name: "Александровск-Сахалинский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Анива",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Долинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Корсаков",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Курильск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Макаров",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Невельск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Оха",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Поронайск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Северо-Курильск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Томари",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Углегорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Холмск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Южно-Сахалинск",
                        primary: true,
						secondary: false
                    },
                ]
            },
            {
                id: 67,
                name: "Свердловская область",
                letter: "С",
                cities: [
                    { 
                        name: "Алапаевск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Арамиль",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Артёмовский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Асбест",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Берёзовский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Богданович",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Верхний Тагил",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Верхняя Пышма",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Верхняя Салда",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Верхняя Тура",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Верхотурье",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Волчанск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дегтярск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Екатеринбург",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Заречный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ивдель",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ирбит",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Каменск-Уральский",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Камышлов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Карпинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Качканар",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кировград",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Краснотурьинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Красноуральск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Красноуфимск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кушва",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лесной",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Михайловск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Невьянск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нижние Серги",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нижний Тагил",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Нижняя Салда",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нижняя Тура",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новая Ляля",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новоуральск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Первоуральск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Полевской",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ревда",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Реж",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Североуральск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Серов",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Среднеуральск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сухой Лог",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сысерть",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тавда",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Талица",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Туринск",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 68,
                name: "Севастополь",
                letter: "С",
                cities: [
                    { 
                        name: "Севастополь",
                        primary: true,
						secondary: false
                    },
                ]
            },
            {
                id: 69,
                name: "Смоленская область",
                letter: "С",
                cities: [
                    { 
                        name: "Велиж",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Вязьма",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Гагарин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Демидов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Десногорск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Дорогобуж",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Духовщина",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ельня",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Починок",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Рославль",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Рудня",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сафоново",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Смоленск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Сычёвка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ярцево",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 70,
                name: "Ставропольский край",
                letter: "С",
                cities: [
                    { 
                        name: "Благодарный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Будённовск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Георгиевск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ессентуки",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Железноводск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Зеленокумск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Изобильный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ипатово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кисловодск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Лермонтов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Минеральные Воды",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Михайловск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Невинномысск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Нефтекумск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новоалександровск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новопавловск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пятигорск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Светлоград",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ставрополь",
                        primary: true,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "Т",
        regions: [
            {
                id: 71,
                name: "Тамбовская область",
                letter: "Т",
                cities: [
                    { 
                        name: "Жердевка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кирсанов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Котовск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мичуринск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Моршанск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Рассказово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тамбов",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Уварово",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 72,
                name: "Тверская область",
                letter: "Т",
                cities: [
                    { 
                        name: "Андреаполь",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бежецк",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Белый",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бологое",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Весьегонск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Вышний Волочёк",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Западная Двина",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Зубцов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Калязин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кашин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кимры",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Конаково",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Красный Холм",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кувшиново",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лихославль",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нелидово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Осташков",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ржев",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Старица",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тверь",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Торжок",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Торопец",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Удомля",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 73,
                name: "Томская область",
                letter: "Т",
                cities: [
                    { 
                        name: "Асино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кедровый",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Колпашево",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Северск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Стрежевой",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Томск",
                        primary: true,
						secondary: false
                    },
                ]
            },
            {
                id: 74,
                name: "Тульская область",
                letter: "Т",
                cities: [
                    { 
                        name: "Алексин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Белёв",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Богородицк",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Болохово",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Венёв",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Донской",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ефремов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кимовск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Киреевск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Липки",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новомосковск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Плавск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Советск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Суворов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тула",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Узловая",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чекалин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Щёкино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ясногорск",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 75,
                name: "Тюменская область",
                letter: "Т",
                cities: [
                    { 
                        name: "Заводоуковск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ишим",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тобольск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Тюмень",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Ялуторовск",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "У",
        regions: [
            {
                id: 76,
                name: "Удмуртская Республика",
                letter: "У",
                cities: [
                    { 
                        name: "Воткинск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Глазов",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Ижевск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Камбарка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Можга",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сарапул",
                        primary: false,
						secondary: true
                    },
                ]
            },
            {
                id: 77,
                name: "Ульяновская область",
                letter: "У",
                cities: [
                    { 
                        name: "Барыш",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Димитровград",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Инза",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новоульяновск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сенгилей",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ульяновск",
                        primary: true,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "Х",
        regions: [
            {
                id: 78,
                name: "Хабаровский край",
                letter: "Х",
                cities: [
                    { 
                        name: "Амурск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бикин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Вяземский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Комсомольск-на-Амуре",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Николаевск-на-Амуре",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Советская Гавань",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Хабаровск",
                        primary: true,
						secondary: false
                    },
                ]
            },
            {
                id: 79,
                name: "Ханты-Мансийский автономный округ — Югра",
                letter: "Х",
                cities: [
                    { 
                        name: "Белоярский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Когалым",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лангепас",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лянтор",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мегион",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нефтеюганск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Нижневартовск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Нягань",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Покачи",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пыть-Ях",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Радужный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Советский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сургут",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Урай",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ханты-Мансийск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Югорск",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "Ч",
        regions: [
            {
                id: 80,
                name: "Челябинская область",
                letter: "Ч",
                cities: [
                    { 
                        name: "Аша",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Бакал",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Верхнеуральск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Верхний Уфалей",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Еманжелинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Златоуст",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Карабаш",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Карталы",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Касли",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Катав-Ивановск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Копейск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Коркино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Куса",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Кыштым",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Магнитогорск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Миасс",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Миньяр",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Нязепетровск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Озёрск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пласт",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сатка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Сим",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Снежинск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Трёхгорный",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Троицк",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Усть-Катав",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чебаркуль",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Челябинск",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Южноуральск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Юрюзань",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 81,
                name: "Чеченская Республика",
                letter: "Ч",
                cities: [
                    { 
                        name: "Аргун",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Грозный",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Гудермес",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Курчалой",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Урус-Мартан",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Шали",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 82,
                name: "Чувашская Республика - Чувашия",
                letter: "Ч",
                cities: [
                    { 
                        name: "Алатырь",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Канаш",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Козловка",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мариинский Посад",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новочебоксарск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Цивильск",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Чебоксары",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Шумерля",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ядрин",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 83,
                name: "Чукотский автономный округ",
                letter: "Ч",
                cities: [
                    { 
                        name: "Анадырь",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Билибино",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Певек",
                        primary: false,
						secondary: false
                    },
                ]
            },
        ]
    },
    {
        letter: "Я",
        regions: [
            {
                id: 84,
                name: "Ямало-Ненецкий автономный округ",
                letter: "Я",
                cities: [
                    { 
                        name: "Губкинский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Лабытнанги",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Муравленко",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Надым",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Новый Уренгой",
                        primary: true,
						secondary: false
                    },
                    { 
                        name: "Ноябрьск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Салехард",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Тарко-Сале",
                        primary: false,
						secondary: false
                    },
                ]
            },
            {
                id: 85,
                name: "Ярославская область",
                letter: "Я",
                cities: [
                    { 
                        name: "Гаврилов-Ям",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Данилов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Любим",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Мышкин",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Переславль-Залесский",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Пошехонье",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ростов",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Рыбинск",
                        primary: false,
						secondary: true
                    },
                    { 
                        name: "Тутаев",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Углич",
                        primary: false,
						secondary: false
                    },
                    { 
                        name: "Ярославль",
                        primary: true,
						secondary: false
                    },
                ]
            },
        ]
    }     
]